<template>
  <div class="home">
    <HomeCover />
    <HomeAbout />
    <HomeKnowledge />
    <HomeProjects />
    <HomeContact />
    <HomeFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeCover from '@/components/HomeCover.vue'
import HomeAbout from '@/components/HomeAbout.vue'
import HomeKnowledge from '@/components/HomeKnowledge.vue'
import HomeProjects from '@/components/HomeProjects.vue'
import HomeContact from '@/components/HomeContact.vue'
import HomeFooter from '@/components/HomeFooter.vue'

export default {
  name: 'Home',
  components: {
    HomeCover,
    HomeAbout,
    HomeKnowledge,
    HomeProjects,
    HomeContact,
    HomeFooter
  },
  mounted () {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault()
        var self = this
        setTimeout(function () {
          document.querySelector(self.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
          })
        }, 100)
      })
    })
  }
}
</script>
