<template>
  <div class="page-projects">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 id="about">{{ $t('home.session.projects') }}</h1>
          <hr />
          <div class="content">Click here to know more about me...</div>
          <div class="btn-wrapper">
            <router-link
              :to="{ name: 'Projects', params: { locale: $i18n.locale } }"
              class="btn btn-grey"
              >Projects</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeProjects'
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.page-projects {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2em 1.5em;
  background: $key-yellow;
  color: $key-grey;
  hr {
    border: none;
    display: block;
    height: 2px;
    background-color: $light-yellow;
    margin: 1rem 0;
    opacity: 1;
  }
  h1 {
    margin: 0 0 0.5em;
    line-height: 1.2;
    text-transform: capitalize;
    font-size: 2em;
    font-weight: 800;
    text-decoration: none;
  }
}
.btn-wrapper {
  display: grid;
  justify-content: center;
}
.btn {
  display: inline-block;
  margin: 10px;
  min-width: 200px;
  padding: 10px 18px;
  border: 2px dashed;
  border-radius: 5px;
  background-color: $btn-background;
  box-shadow: 8px 8px 0 $btn-shadow;
  line-height: 1em;
  font-size: 1.1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  color: $key-yellow;

  &.btn-green {
    color: $key-green;
  }
  &.btn-grey {
    background-color: $light-yellow;
    box-shadow: 8px 8px 0 #b4852b;
    color: $key-grey;
  }
  &:hover {
    border-style: solid;
  }
}
</style>
