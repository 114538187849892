<template>
  <div class="page-footer">
    <div class="container">
      <div class="row">
        <div class="col">
          <ul class="icon-wrapper">
            <li>
              <a href="https://code.it9.hk" target="_blank"
                ><i class="fa-solid fa-code"></i
              ></a>
            </li>
            <li>
              <a href="https://github.com/basiclaw" target="_blank"
                ><i class="fa-brands fa-github"></i
              ></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/basic-law/" target="_blank"
                ><i class="fa-brands fa-linkedin"></i
              ></a>
            </li>
            <li>
              <a href="mailto:basiclaw@it9.hk"
                ><i class="fa-solid fa-envelope"></i
              ></a>
            </li>
          </ul>
          <p>&copy; 2022 Basic Law</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeFooter'
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.page-footer {
  display: flex;
  flex-direction: column;
  padding: 0 20px 80px;
  background: $dark-grey;
  color: $beige;
  font-size: 0.8em;
  text-align: center;
  .icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.5em;
    margin: 0 0 1em;
    padding: 0;
    border: 0;
    list-style: none;
    a {
      font-size: 1.7em;
      color: inherit;

      &:hover {
        color: $key-yellow;
      }
    }
  }
}
</style>
