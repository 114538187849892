<template>
  <div class="page-cover">
    <vue-particles
      class="cover-background"
      color="#f096a0"
      :particleOpacity="0.7"
      :particlesNumber="150"
      shapeType="circle"
      :particleSize="3"
      linesColor="#f096a0"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="1"
      :hoverEffect="false"
      :clickEffect="false"
    ></vue-particles>
    <div class="cover-content">
      <h1>&lt;{{ $t('home.name') }} /&gt;</h1>
      <h2>&lt;{{ $t('home.title') }} /&gt;</h2>
      <div class="btn-wrapper">
        <a href="#about" class="btn btn-green">{{
          $t('home.session.about')
        }}</a>
        <a href="#knowledge" class="btn btn-green">{{
          $t('home.session.knowledge')
        }}</a>
        <!-- <router-link to="/blog" class="btn btn-green">Blog</router-link> -->
        <router-link
          :to="{ name: 'Projects', params: { locale: $i18n.locale } }"
          class="btn btn-green"
          >{{ $t('home.session.projects') }}</router-link
        >
        <router-link
          :to="{ name: 'ToolsHome', params: { locale: $i18n.locale } }"
          class="btn btn-green"
          >{{ $t('home.session.toolbox') }}</router-link
        >
        <a href="mailto:basiclaw@it9.hk" class="btn"
          ><i class="fa-solid fa-envelope"></i>
          {{ $t('home.session.contactMe') }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeCover'
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.page-cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background: $dark-grey;
  color: $beige;
  .cover-background {
    height: 100vh;
  }
  .cover-content {
    position: absolute;
    width: 100%;
  }
  h1 {
    margin-bottom: 10px;
    text-align: center;
    color: $key-yellow;
    font-size: 2.8em;
    font-weight: 700;
  }
  h2 {
    margin: 0 0 0.5em;
    text-align: center;
    font-size: 1.3em;
    font-weight: 400;
    line-height: 1.2;
    text-transform: capitalize;
    text-decoration-thickness: 10%;
  }
}
.btn-wrapper {
  display: grid;
  justify-content: center;
}
.btn {
  display: inline-block;
  margin: 10px;
  padding: 10px 18px;
  border: 2px dashed;
  border-radius: 5px;
  background-color: $btn-background;
  box-shadow: 8px 8px 0 $btn-shadow;
  line-height: 1em;
  font-size: 1.1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  color: $key-yellow;

  &.btn-green {
    color: $key-green;
  }
  &:hover {
    border-style: solid;
  }
}
</style>
