<template>
  <div class="page-knowledge">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 id="knowledge">{{ $t('home.session.knowledge') }}</h1>
          <hr />
          <div class="row">
            <div class="col col-12 col-md-6">
              <h2>Languages</h2>
              <hr />
              <div class="tool-items">
                <div v-for="lang in languages" :key="lang">
                  <code>{{ lang }}</code>
                </div>
              </div>
            </div>
            <div class="col col-12 col-md-3">
              <h2>Tools</h2>
              <hr />
              <div class="tool-items">
                <div v-for="tool in tools_and_techniques" :key="tool">
                  <code>{{ tool }}</code>
                </div>
              </div>
            </div>
            <div class="col col-12 col-md-3">
              <h2>Software</h2>
              <hr />
              <div class="tool-items">
                <div v-for="ware in software" :key="ware">
                  <code>{{ ware }}</code>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  name: 'HomeKnowledge',
  data: function () {
    return {
      languages: [],
      tools_and_techniques: [],
      software: []
    }
  },
  beforeMount () {
    axios.get(this.$store.state.endpoint + 'knowledge').then((response) => {
      try {
        response = response.data
        if (response.status !== 'success') {
          throw response.error.length > 0 ? response.error[0] : 'Unknown Error'
        }
        response.data.forEach((data) => {
          this.languages = data.languages ? data.languages.split('\r\n') : []
          this.tools_and_techniques = data.tools_and_techniques
            ? data.tools_and_techniques.split('\r\n')
            : []
          this.software = data.software ? data.software.split('\r\n') : []
          return false
        })
      } catch (e) {
        console.error(e)
      }
    })
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.page-knowledge {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2em 1.5em;
  background: $dark-grey;
  color: $beige;
  hr {
    border: none;
    display: block;
    height: 2px;
    background-color: $light-grey;
    margin: 1rem 0;
    opacity: 1;
  }
  h1 {
    margin: 0 0 0.5em;
    color: $key-yellow;
    line-height: 1.2;
    text-transform: capitalize;
    font-size: 2em;
    font-weight: 800;
    text-decoration: none;
  }
  h2 {
    margin: 0.5em 0;
    font-size: 1.3em;
    font-weight: 400;
    line-height: 1.2;
    text-transform: capitalize;
    text-decoration-thickness: 10%;
  }
  code {
    font-size: calc(1em - 2px);
    background-color: $light-grey;
    color: $beige;
    padding: 0.2em 0.4em;
    border-radius: 2px;
    white-space: nowrap;
  }
  .tool-items {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(9, 1fr);
    gap: 5px;
  }
}

@media (min-width: 992px) {
  .page-knowledge {
    .tool-items {
      grid-template-rows: repeat(6, 1fr);
    }
  }
}
</style>
