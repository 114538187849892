<template>
  <div class="page-about">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 id="about">{{ $t('home.session.about') }}</h1>
          <hr />
          <div class="content" v-html="content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  name: 'HomeAbout',
  data: function () {
    return {
      content: ''
    }
  },
  beforeMount () {
    axios.get(this.$store.state.endpoint + 'about').then((response) => {
      try {
        response = response.data
        if (response.status !== 'success') {
          throw response.error.length > 0 ? response.error[0] : 'Unknown Error'
        }
        response.data.forEach((data) => {
          this.content = data.content
          this.content = this.content.replaceAll('[[lang]]', this.$store.state.locale)
          return false
        })
      } catch (e) {
        console.error(e)
      }
    })
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.page-about {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2em 1.5em;
  background: $key-yellow;
  color: $key-grey;
  hr {
    border: none;
    display: block;
    height: 2px;
    background-color: $light-yellow;
    margin: 1rem 0;
    opacity: 1;
  }
  h1 {
    margin: 0 0 0.5em;
    line-height: 1.2;
    text-transform: capitalize;
    font-size: 2em;
    font-weight: 800;
    text-decoration: none;
  }
  .content {
    :deep(code) {
      font-size: calc(1em - 2px);
      background-color: $light-yellow;
      color: $key-grey;
      padding: 0.2em 0.4em;
      border-radius: 2px;
      white-space: nowrap;
    }
    :deep(a) {
      color: $key-grey;
      font-weight: 800;

      &:hover {
        background-color: $light-yellow;
      }
    }
  }
}
:deep(.fa-arrow-up-right-from-square) {
  font-size: 0.8em;
  padding: 0 0 0 4px;
}
</style>
