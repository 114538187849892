<template>
  <div class="page-contact">
    <h1>&lt;{{ $t('home.contact') }} /&gt;</h1>
    <h2>Basic Law &lt;basiclaw@it9.hk&gt;</h2>
    <div class="btn-wrapper">
      <a href="mailto:basiclaw@it9.hk" class="btn"
        ><i class="fa-solid fa-envelope"></i>
        {{ $t('home.session.contactMe') }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeContact'
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.page-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 1.5em;
  background: $dark-grey;
  color: $beige;
  h1 {
    margin-bottom: 10px;
    text-align: center;
    color: $key-yellow;
    font-size: 2.8em;
    font-weight: 700;
  }
  h2 {
    margin: 0 0 0.5em;
    text-align: center;
    font-size: 1.3em;
    font-weight: 400;
    line-height: 1.2;
    text-decoration-thickness: 10%;
  }
}
.btn-wrapper {
  display: grid;
  justify-content: center;
}
.btn {
  display: inline-block;
  margin: 10px;
  padding: 10px 18px;
  border: 2px dashed;
  border-radius: 5px;
  background-color: $btn-background;
  box-shadow: 8px 8px 0 $btn-shadow;
  line-height: 1em;
  font-size: 1.1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  color: $key-yellow;

  &.btn-green {
    color: $key-green;
  }
  &:hover {
    border-style: solid;
  }
}
</style>
